<template>
    <div class="container-fluid">
        <section class="content">
            <div class="row">
                <!-- Ocultar esta columna en la creación de una compañía -->
                <div class="col-md-2">
                </div> 
                <div class="col-md-10">
                    <div class="card card-default card-tabs">
                        <div class="card-header p-1 pt-1">
                            <ul class="nav nav-pills">
                                <li class="nav-item"><a class="nav-link active" href="#pestanya_pedidos" data-toggle="tab">{{$t('general.pedidos')}}</a></li>  
                                <li class="nav-item"><a class="nav-link" href="#pestanya_proveedores" data-toggle="tab">{{$t('general.proveedores')}}</a></li>
                                <li class="nav-item"><a class="nav-link" href="#pestanya_articulos" data-toggle="tab">{{ $t('general.articulos') }}</a></li>
                                <li class="nav-item"><a class="nav-link" href="#pestanya_almacenes" data-toggle="tab">{{$t('general.almacenes')}}</a></li>
                            </ul>
                        </div>
                    </div>
                            <br>
                            <div class="tab-content">
                                <div class="tab-pane active" id="pestanya_pedidos">
                                    <pedidos />
                                </div>
                                <div class="tab-pane" id="pestanya_proveedores">
                                    <proveedores />
                                </div>                            
                                <div class="tab-pane" id="pestanya_articulos">
                                    
                                </div>
                                <div class="tab-pane" id="pestanya_almacenes">
                                    
                                </div>
                            </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import proveedores from '../proveedores/ProveedoresPage.vue'
import pedidos from './PedidosLista.vue'

export default {
    components: {
        proveedores,
        pedidos
    },
    data() {
        return {
        }
    }
}
</script>